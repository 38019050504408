
<template>
     <v-card class="pa-3 dayprogramContainerBig">
        <Dayprogram :showTitle="true"></Dayprogram>
    </v-card>
</template>


<script>
import Vue from "vue";
import Dayprogram from "@/components/Dayprogram.vue";

export default Vue.extend({
    components: {
        Dayprogram
    },
    data() {
        return {};
    }
});

</script>

<style>

    .dayprogramContainerBig{
        height: calc(100vh - 24px); /* fallback */
        height: calc(100dvh - 24px);
    }

    @media screen and (max-width: 1263px){
        
        .dayprogramContainerBig{
            height: calc(100vh - 24px - 60px); /* fallback */
            height: calc(100dvh - 24px - 60px);
        }

    }

</style>